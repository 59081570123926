import './App.css';
import { BrowserRouter as Layout, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Join from './pages/Join';
import JoinEmail from './pages/JoinEmail';
import JoinCalendar from './pages/JoinCalendar';
import JoinWatchlist from './pages/JoinWatchlist';
import JoinSuccess from './pages/JoinSuccess';
import ForwardEmail from './pages/ForwardEmail';
import Focus from './pages/Focus';
import Profile from './pages/Profile';
import MessageComponent from './components/MessageComponent';
import FeedContextLayout from './components/FeedContextLayout';
import EventComponent from './components/EventComponent';
import { GlobalPromptsProvider } from './components/GlobalPromptsContext'; // Import the context provider
import ForwardFilter from './pages/ForwardFilter';
import ForwardSettings from './pages/ForwardSettings';
import ForwardAddresses from './pages/ForwardAddresses';
import ForwardConfirmation from './pages/ForwardConfirmation';
import ForwardAllow from './pages/ForwardAllow';
import ForwardSuccess from './pages/ForwardSuccess';
import ForwardInbox from './pages/ForwardInbox';
import FilterSettings from './pages/FilterSettings';
import FilterCreate from './pages/FilterCreate';
import FilterAddress from './pages/FilterAddress';
import FilterSuccess from './pages/FilterSuccess';
//import posthog from 'posthog-js'

const App = () => {

  /*
  posthog.init('phc_l2r4ZVdWQRu16y66kW0YXTF5SEcejLaq4H1DUZxVWJW',
      {
          api_host: 'https://us.i.posthog.com',
          person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
      }
  )
  */

  return (
    <GlobalPromptsProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/join" element={<Join />} />
          <Route path="/join/email" element={<JoinEmail />} />
          <Route path="/join/watchlist" element={<JoinWatchlist />} />
          <Route path="/join/calendar" element={<JoinCalendar />} />
          <Route path="/forward/email" element={<ForwardEmail />} />
          <Route path="/forward/addresses" element={<ForwardAddresses />} />
          <Route path="/forward/settings" element={<ForwardSettings />} />
          <Route path="/forward/inbox" element={<ForwardInbox />} />
          <Route path="/forward/confirmation" element={<ForwardConfirmation />} />
          <Route path="/forward/allow" element={<ForwardAllow />} />
          <Route path="/forward/success" element={<ForwardSuccess />} />
          <Route path="/forward/filter" element={<ForwardFilter />} />
          <Route path='/filter/settings' element={<FilterSettings/>} />
          <Route path='/filter/create' element={<FilterCreate/>} />
          <Route path='/filter/address' element={<FilterAddress/>} />
          <Route path='/filter/success' element={<FilterSuccess/>} />
          <Route element={<FeedContextLayout />} >
            <Route path="/join/success" element={<JoinSuccess />} />
            <Route path="/focus" element={<Focus />} >
              <Route path="/focus/event/:id" element={<EventComponent />} />
              <Route path="/focus/message/:id" element={<MessageComponent />} />
            </Route>
          </Route>
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </Layout>
    </GlobalPromptsProvider>
  );
};

export default App;