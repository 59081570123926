import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { formatEventDate, isToday } from '../utils/FormatDates';
import { getDemoMap, getDemoMessage, setDemoMode } from '../utils/Demo';

// Create a context
const FeedContext = createContext();

const FeedProvider = React.memo(({ children }) => {
  /* Loading States */
  const firstRunRef = useRef(false);
  const [ESloadingState, setESLoadingState] = useState(null);
  const [loadComplete, setLoadComplete] = useState(false);

  /* Replace events with demo data - first run tutorial */
  const [demoMode, setDemoMode] = useState(null);
  const demoModeRef = useRef(demoMode);

  const demoEvents = getDemoMap();
  const [demoMessage, setDemoMessage] = useState(null);

  /* Model */
  const [startTimestamp, setStartTimestamp] = useState(Date.now()); // Determines 'today'
  const [events, setEvents] = useState(null); // Updating events updates days
  const [eventCount, setEventCount] = useState(0);
  const [days, setDays] = useState(new Map());
  const [months, setMonths] = useState(new Map());
  const [monthEvents, setMonthEvents] = useState(new Map());
  const [userProfile, setUserProfile] = useState(null); // User profile is separate fetch
  const [currentWeather, setCurrentWeather] = useState(null);
  const [weatherForecast, setWeatherForecast] = useState(null);

  // SSE source
  const eventSourceRef = useRef(null);

  /* Abort events and profile controllers */
  const abortController = useRef(null);
  const abortProfileController = useRef(null);
  const abortWeatherController = useRef(null);

  /* Updates an event in the event model */
  const updateEvent = (updatedEvent) => {
    console.log('FeedProvider: updateEvent');
    const eventToUpdate = events.get(updatedEvent._id);
    if (eventToUpdate) {
      // Directly update the properties of the existing event
      Object.assign(eventToUpdate, updatedEvent);

      // Update the events state with the modified Map
      const newEvents = new Map(events);
      setEvents(newEvents);
      setDateMaps(newEvents);
    }
  };

  const init = async () => {
    console.log('FeedContext: init');
    fetchProfileData(); // Grab profile data once

    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource('/api/checkmessages');
      eventSourceRef.current.addEventListener('open', onopen);
      eventSourceRef.current.addEventListener('message', onmessage);
      eventSourceRef.current.addEventListener('error', onerror);
    }
  }

  const destroy = () => {
    console.log('FeedContext: destroy');
    if (abortController.current) {
      abortController.current.abort();
    }

    if (abortProfileController.current) {
      abortProfileController.current.abort();
    }

    if (abortWeatherController.current) {
      abortWeatherController.current.abort();
    }

    if (eventSourceRef.current && eventSourceRef.current.readyState !== EventSource.CLOSED) {
      eventSourceRef.current.removeEventListener('open', onopen);
      eventSourceRef.current.removeEventListener('message', onmessage);
      eventSourceRef.current.removeEventListener('error', onerror);
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
  }

  /* Grabs the user profile data and sets userProfile */
  const fetchProfileData = async () => {
    console.log('FeedContext: fetchProfileData');

    abortProfileController.current = new AbortController();

    try {
      // Fetch profile data
      const profileResponse = await fetch('/api/user/profile', { signal: abortProfileController.current.signal });

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();

        if (profileData.user) {
          console.log('FeedContext: setUserProfile');
          setUserProfile(profileData.user);

          if (profileData.user.preferences && profileData.user.preferences.firstViewTutorial) {
            console.log('Demo mode should be turned off.');
            setDemoMode(false);
          } else {
            console.log('Demo mode should be turned on');
            setDemoMode(true);
          }
        }
      } else if (profileResponse.status === 401) {
        window.location.href = '/'; // Redirect to login page
      } else {
        console.error('Failed to fetch profile data');
      }

    } catch (error) {
      console.log('Error fetching profile data or geocode:', error.message);
    }
  };


  /* Grabs all events from api */
  const fetchEvents = async () => {
    console.log('FeedContext: fetchEvents');
    if (demoModeRef.current) { // Exit as in demo mode
      console.log('FeedContext: fetchEvents - demoMode: ' + demoModeRef.current)
      return;
    }

    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();

    try {
      // Clear previous data before fetching new data
      setEvents(null);
      setDays(new Map());
      setMonths(new Map());
      console.log('fetchEvents: setMonths');
      setMonthEvents(new Map());
      setEventCount(0); // Set event count to 0

      const eventsResponse = await fetch('/api/events', { signal: abortController.current.signal }); // Pass signal to fetch

      if (eventsResponse.ok) {
        const eventsData = await eventsResponse.json();
        const eventsArray = eventsData.events;
        const eventsMap = new Map(eventsArray.map(event => [event._id, event]));

        setEventsAndDates(eventsMap);

        if (eventsMap.size === 0) {
          console.log('FeedContext: fetchEvents set firstRun true');
          firstRunRef.current = true;
        }

      } else if (eventsResponse.status === 401) {
        window.location.href = '/'; // Redirect to login page

      } else {
        console.error('Error:', eventsResponse.status, eventsResponse.statusText);
      }

    } catch (error) {
      // TODO: This catches and errors from Abort Controller on destroy. 
    }
  }

  const fetchWeather = async () => {
    console.log('FeedContext: fetchWeather');
    if (currentWeather || weatherForecast) {
      console.log('Weather already fetched.')
      return;
    }
  
    try {
      // Initialize an AbortController for canceling fetch requests if needed
      abortWeatherController.current = new AbortController();
      const { signal } = abortWeatherController.current;
  
      // Make both requests in parallel using Promise.all
      const [currentWeatherResponse, weatherForecastResponse] = await Promise.all([
        fetch('/api/weather/current', { signal }),
        fetch('/api/weather/forecast', { signal }),
      ]);
  
      // Check if both responses are successful
      if (!currentWeatherResponse.ok || !weatherForecastResponse.ok) {
        throw new Error(
          `Failed to fetch weather data: Current Weather Status ${currentWeatherResponse.status}, Forecast Status ${weatherForecastResponse.status}`
        );
      }
  
      // Parse JSON responses in parallel
      const [currentWeatherData, weatherForecastData] = await Promise.all([
        currentWeatherResponse.json(),
        weatherForecastResponse.json(),
      ]);

      setCurrentWeather(currentWeatherData.currentWeather);
      setWeatherForecast(weatherForecastData.forecastDaily);
  
    } catch (error) {
      // Handle errors (e.g., fetch failures, aborts)
      if (error.name === 'AbortError') {
        console.log('Weather fetch aborted');
      } else {
        console.error('Error fetching weather data:', error.message);
      }
    } finally {
      // Clean up or reset after the fetch
      console.log('Fetch completed');
    }
  };
  

  const requestUserPreferencesUpdate = async (viewed) => {

    const preferences = {}
    preferences.firstViewTutorial = viewed;

    try {
      const response = await fetch('/api/user/preferences', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ preferences }),
      });

      if (response.ok) {
        console.log('User Preferences: first view tutorial updated successfully');
      } else {
        console.error('Failed to update user preferences');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  /* Sets events and days and adds local times for events. Server times are UTC */
  const setEventsAndDates = (events) => {
    console.log('FeedContext: setEventsAndDates');

    events.forEach(function (event) {
      event.startLocal = formatEventDate(event.start, event.dateTime);

      event.tentative = event.tags.includes('tentative');
      event.cancel = event.tags.includes('cancel');
      event.update = event.tags.includes('update');
      event.reschedule = event.tags.includes('reschedule');
    
      // Determine if there's an end date
      const relevantDate = event.end
        ? formatEventDate(event.end, event.dateTime)
        : event.startLocal;
    
      // Check if the event is upcoming
      const isFutureDateTime = event.dateTime && moment(relevantDate).isAfter(moment());
      const isFutureDateOnly = !event.dateTime && moment(relevantDate).isSameOrAfter(moment(), 'day');
    
      event.upcoming = isFutureDateTime || isFutureDateOnly;
    
      // Add the endLocal property if event.end exists
      if (event.end) {
        event.endLocal = relevantDate;
      }
    });
    

    setEvents(events);
    setEventCount(events.size);

    setDateMaps(events);
  }

  /* Creates new maps for days, months, and monthEvents states */
  const setDateMaps = (events) => {
    console.log('FeedContext: setDateMaps');

    if (events && events.size > 0) {
      let eventsToday = false;
      const daysMap = new Map();
      const monthsMap = new Map();
      const monthEventsMap = new Map();

      // Organize events into days and months simultaneously
      events.forEach(function (event, eventId) {
        // Filter out disliked events before processing
        if (event.dislike) {
          return; // Skip disliked events
        }

        const day = moment(event.startLocal).format('YYYY-MM-DD');

        // Check if there are events for today
        if (moment(day).isSame(moment(), 'day')) {
          eventsToday = true;
        }

        // Organize events by day
        if (!daysMap.has(day)) {
          daysMap.set(day, []);
        }
        daysMap.get(day).push(event);
      });

      // Ensure there's an entry for today if no events are found for it
      if (!eventsToday) {
        const today = moment().format('YYYY-MM-DD');
        daysMap.set(today, []);
      }

      // Create a local variable to hold the updated days data
      const updatedDays = new Map();

      // Loop through each day in the new data
      daysMap.forEach((newEvents, day) => {
        const existingEvents = updatedDays.get(day) || [];

        // Use a Set to ensure events are unique based on _id
        const uniqueEvents = new Map();

        // Add existing events to the Map
        existingEvents.forEach(event => uniqueEvents.set(event._id, event));

        // Add new events to the Map
        newEvents.forEach(event => uniqueEvents.set(event._id, event));

        // Update the day with unique events as an array
        const filteredEvents = Array.from(uniqueEvents.values());

        // Only update if there are events for the day
        if (filteredEvents.length > 0 || moment(day).isSame(moment(), 'day')) {
          updatedDays.set(day, filteredEvents);
        } else {
          updatedDays.delete(day);
        }
      });

      // Sort the events of the day by start timestamp
      updatedDays.forEach((eventsList, day) => {
        eventsList.sort((a, b) => {
          if (a.dateTime && !b.dateTime) {
            return -1;
          } else if (!a.dateTime && b.dateTime) {
            return 1;
          } else {
            return moment(a.start).valueOf() - moment(b.start).valueOf();
          }
        });
      });

      // Sort the updatedDays map and save to a local variable
      const sortedDays = new Map([...updatedDays.entries()].sort());
      setDays(sortedDays);

      // Use sortedDays to create monthsMap and monthEventsMap
      sortedDays.forEach((events, day) => {
        const monthKey = moment(day).format('YYYY-MM'); // Group by month

        // Ensure the month exists in the maps
        if (!monthsMap.has(monthKey)) {
          monthsMap.set(monthKey, []);
        }

        // Process events for the current day
        if (moment(day).isSame(moment(), 'day') && !eventsToday) {
          const monthDays = monthsMap.get(monthKey) || [];
          monthDays.push({ day, events: [] });
          monthsMap.set(monthKey, monthDays);
        }

        events.forEach((event) => {
          if (event.monthEvent) {
            // Only add to monthEventsMap if that month has month events.
            if (!monthEventsMap.has(monthKey)) {
              monthEventsMap.set(monthKey, []);
            }
            monthEventsMap.get(monthKey).push(event);

            // **Edge Condition Fix**: Ensure the first of the month exists in monthsMap
            const firstOfMonth = moment(monthKey).format('YYYY-MM-DD');
            const monthDays = monthsMap.get(monthKey) || [];
            const firstDayEntry = monthDays.find((entry) => entry.day === firstOfMonth);

            if (!firstDayEntry && moment(day).isSame(moment(), 'day')) {
              monthDays.push({ day: firstOfMonth, events: [] });
              monthsMap.set(monthKey, monthDays);
            }

          } else {
            // Add to monthsMap
            const monthDays = monthsMap.get(monthKey) || [];

            // Check if the day already exists in monthsMap
            const dayEntry = monthDays.find((entry) => entry.day === day);

            if (dayEntry) {
              // Add the event to the existing day's events array
              dayEntry.events.push(event);
            } else {
              // Create a new day entry with the event
              monthDays.push({ day, events: [event] });
            }

            // Update monthsMap with the modified days array
            monthsMap.set(monthKey, monthDays);
          }
        });
      });


      // Set the updated maps
      console.log('setDateMaps: setMonths');
      setMonths(monthsMap);
      setMonthEvents(monthEventsMap);

      //console.log(sortedDays);
      //console.log(monthsMap);
      //console.log(monthEventsMap);
    }
  };


  const onopen = () => {
    setESLoadingState('loading'); // Indicate that SSE connection is open
  };

  const onmessage = (event) => {
    console.log('FeedContext: onmessage');

    const data = JSON.parse(event.data);

    if (firstRunRef.current && data.currentProgress > 0 && data.eventsCount > 0) { // First run, so show first set of messages found
      firstRunRef.current = false;
      console.log('FeedContext: onmessage - firstrun, fetchEvents');
      fetchEvents(); // Grab EVENTS on first set of events parsed.
    }

    if (data.currentProgress < data.totalProgress) { // Messages
      setESLoadingState('progress');

    } else if (data.currentProgress === data.totalProgress) { // all messages checked
      eventSourceRef.current.close();
      setESLoadingState('complete');

      if (data.eventsCount > 0) {
        console.log('FeedContext: onmessage - complete, fetch events');
        fetchEvents(); // Grab EVENTS after all events have been parsed.
      }
    }
  };

  const onerror = () => {
    if (eventSourceRef.current.readyState === EventSource.CLOSED) {
      setESLoadingState('closed');
      eventSourceRef.current.close();
    } else if (eventSourceRef.current.readyState === EventSource.CONNECTING) {
      setESLoadingState('loading');
    } else {
      setESLoadingState('error');
    }
  };

  const demoModeOn = () => {
    console.log('FeedContext: demoMode on');
    setDemoMessage(getDemoMessage(userProfile.displayName));
    setEvents(demoEvents); // Set events manually
    setDateMaps(demoEvents); // Normally days is set after fetching events
    setEventCount(demoEvents.size);
  }

  const demoModeOff = () => {
    console.log('FeedContext: demoMode off ');
    if (!userProfile.preferences || !userProfile.preferences.firstViewTutorial) {
      requestUserPreferencesUpdate(true);
    }
  }

  /* Initialize component */
  useEffect(() => { // Check Messages and fetch events when component mounts.
    init();
    // Cleanup function
    return () => {
      destroy();
    };
  }, []);


  /* Keep demo mode as a reference and updated */
  useEffect(() => {
    demoModeRef.current = demoMode; // Keep the ref updated
  }, [demoMode]);

  /* Use effect is called at least once on startup */
  useEffect(() => {
    if (userProfile) {
      // Handle demo mode
      if (demoMode) {
        demoModeOn();
      } else {
        demoModeOff();
      }

      // Fetch events
      fetchEvents();
      fetchWeather();
    }
  }, [userProfile, demoMode]);


  useEffect(() => {
    if (ESloadingState === 'complete'
      && events
      && events.size > 0
      && events.size == eventCount) {

      setLoadComplete(true);
      console.log('FeedContext: load complete')
    }
  }, [ESloadingState]);

  return (
    <FeedContext.Provider value={{
      userProfile,
      startTimestamp,
      events,
      days,
      months,
      monthEvents,
      eventCount,
      eventSourceRef,
      ESloadingState,
      loadComplete,
      demoMode,
      demoMessage,
      currentWeather,
      weatherForecast,
      fetchEvents,
      updateEvent,
      setDemoMode
    }}>
      {children}
    </FeedContext.Provider>
  );
});

export { FeedProvider, FeedContext };
