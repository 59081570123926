import React, { useState, useEffect } from 'react';

const ForwardInbox = () => {
  const [advance, setAdvance] = useState(true);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Enter Forwarding Address</h2></div>
      <div className="onboarding-main">
        <h3>Add this forwarding address <br/>to Gmail on desktop</h3>
        <div className='forwarding-address'>inbox@simpleschedule.me</div>
        <div className='note'>Type this exact address</div>
        <br/>
        <p>Then click '<b>next</b>'</p>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/confirmation' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardInbox;
