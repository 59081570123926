import React, { useState, useEffect } from 'react';

const ForwardEmail = () => {
  const [advance, setAdvance] = useState(false);
  const [profile, setProfile] = useState(null);
  const [lastLocation, setLastLocation] = useState(null);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  const fetchProfileData = async (signal) => {
    try {
      // Fetch the user profile with the provided AbortController signal
      const profileResponse = await fetch('/api/user/profile', { signal });

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();

        setProfile(profileData.user);

        if (profileData.user) {
          setLastLocation(profileData.user.lastLocation);
        }

        // Sequentially save the user's timezone after fetching the profile
        await saveUserTimezone(signal);
      } else {
        console.error('Failed to fetch profile data');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching profile data:', error);
      }
    }
  };

  const saveUserTimezone = async (signal) => {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Fetch request with the provided AbortController signal
      const response = await fetch('/api/timezone', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ timezone }),
        signal,
      });

      if (response.ok) {
        console.log('Timezone saved successfully');
      } else {
        console.error('Failed to save timezone');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Timezone fetch aborted');
      } else {
        console.error('Error saving timezone:', error);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchProfileData(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className='email onboarding'>
      <div className="onboarding-title"><h2>Simple Schedule needs<br/> Email Access</h2></div>
      <div className="onboarding-main">
        <h3>Your schedule is created from<br /> the email messages sent to you</h3>
        <h3>Email forwarding is required<br /> to access your messages</h3>
      </div>
      <div className="onboarding-continue">
        <button className='continue' onClick={() => { window.location.href = '/forward/addresses' }}>Setup Forwarding</button>
        <button onClick={() => window.location.href = '/join/success'} className="cancel">Do Later</button>
      </div>
    </div>
  );
};

export default ForwardEmail;
