import React from 'react';
import SuccessIcon from '../assets/SuccessIcon';

const JoinSuccess = () => {
  return (
    <div className='success onboarding'>
      <div className="onboarding-title"><h1>Ready</h1></div>
      <div className="onboarding-main">
        <SuccessIcon size={120} color="#ff6700" />
      </div>
      <div className="onboarding-continue">
          <button className='continue' onClick={() => { window.location.href = '/focus' }}>Continue</button>
      </div>
    </div>
  );
};

export default JoinSuccess;
