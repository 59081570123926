import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import FocusFeed from '../components/FocusFeed';
import MessageComponent from '../components/MessageComponent';
import EventComponent from '../components/EventComponent';
import CreateComponent from '../components/CreateComponent';
import { FeedContext } from '../components/FeedContext';


const Focus = () => {
  const { demoMode } = useContext(FeedContext);
  const [eventId, setEventId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [sourceType, setSourceType] = useState(null);
  const navigate = useNavigate();

  const openEvent = useCallback(debounce((id) => {
    console.log('openEvent');
    setEventId(id);
    navigate(`/focus/event/${id}`);
  }, 300), []);

  const closeEvent = () => {
    console.log('closeEvent');
    setEventId(null);
    navigate(`/focus`);
  };

  const openMessage = useCallback(debounce((id, type) => {
    setSourceId(id);
    setSourceType(type);
    navigate(`/focus/message/${id}`);
  }, 300), []);

  const closeMessage = () => {
    setSourceId(null);
    navigate(`/focus`);
  };

  return (
    <div className="focus">
      {eventId && <EventComponent eventId={eventId} closeEvent={closeEvent} openMessage={openMessage} />}
      {sourceId && <MessageComponent sourceId={sourceId} sourceType={sourceType} closeMessage={closeMessage} openEvent={openEvent} />}
      <FocusFeed openEvent={openEvent} closeEvent={closeEvent} openMessage={openMessage} closeMessage={closeMessage} />
      {!demoMode && <CreateComponent openMessage={openMessage} />}
    </div>
  );
};

export default Focus;
