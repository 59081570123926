import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faGear } from '@fortawesome/free-solid-svg-icons';

const ForwardAllow = () => {
  const [advance, setAdvance] = useState(false);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  return (
    <div className='onboarding'>
      <div className="onboarding-title"><h2>Allow Forwarding</h2></div>
      <div className="onboarding-main">
        <h3>Follow this step and return here</h3>
        <button onClick={() => {setAdvance(true); window.open('https://mail.google.com/mail/vf-%5BANGjdJ-in1yNaA4KMBaeqWfK2NiAef8XVCjipgBWJPPzfBh6bSP9sko-_TP36_Gyk6jk2LXNnpxePiTclaNRtNLsMjtGnBEnmiZ8tKVktWtYH5w6Sizve2Layc3_hkM%5D-FTXpw48Vl764W4J8bMi6JStdT7g') }}><FontAwesomeIcon className="icon" icon={faArrowUpRightFromSquare} /> Confirm Email Forwarding</button><br/>
        <div className='note'>Gmail asks you to confirm sending <br/>mail to inbox@simpleschedule.me</div>
      </div>
      <div className="onboarding-continue">
        <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/forward/success' }}>Continue</button>
        <button onClick={() => window.history.back()} className="cancel">Go Back</button>
      </div>
    </div>
  );
};

export default ForwardAllow;
