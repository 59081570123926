import React, { useState, useEffect, useContext } from 'react';
import CircleProgress from './CircleProgress'
import { FeedContext } from './FeedContext';
import '../styles/CheckMessages.css'

const CheckMessages = () => {
    const { eventSourceRef, ESloadingState, eventCount } = useContext(FeedContext);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [totalProgress, setTotalProgress] = useState(0);
    const [eventsCount, setEventsCount] = useState(0);

    useEffect(() => {
        if (eventSourceRef.current) {
            const eventSource = eventSourceRef.current;

            const onmessage = (event) => {
                const data = JSON.parse(event.data);
                setCurrentProgress(data.currentProgress);
                setTotalProgress(data.totalProgress);
                setEventsCount(data.eventsCount);
            };

            eventSource.addEventListener('message', onmessage);

            return () => { // Cleanup function
                if (eventSource) {
                    eventSource.removeEventListener('message', onmessage);
                }
            };
        }
        // eslint-disable-next-line    
    }, [eventSourceRef.current]);

    const renderContent = () => {
        let progressOutput;

        switch (ESloadingState) {
            case 'loading':
                progressOutput = <div className="progress loading"><CircleProgress percentage={0} size={14} /></div>;
                break;
            case 'progress':
                progressOutput = <div className="progress active"><CircleProgress percentage={currentProgress > 0 ? Math.round(currentProgress / totalProgress * 100) : 0} size={14} /></div>;
                break;
            case 'complete':
                progressOutput = <div className="progress complete"><CircleProgress percentage={100} size={14} /></div>;
                break;
            case 'closed':
                progressOutput = <div className="progress">c</div>;
                break;
            case 'error':
                progressOutput = <div className="progress">e</div>;
                break;
            default:
                progressOutput = null;
        }

        return (
            <div className={`progress-counter${' '+ESloadingState}`}>
                {eventCount > eventsCount ? (
                    <span className='eventCount'>{eventCount} events</span>
                ) : (
                    <span className='eventCount'>+{eventsCount} events</span>
                )}
                {progressOutput}
            </div>

        );
    };


    return (
        renderContent()
    );
};

export default CheckMessages;