import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarRegular, faCalendar, faCalendarCheck, faCalendarPlus, faCalendarXmark, faSquareCheck, faFlag, faHourglass } from '@fortawesome/free-regular-svg-icons'
import { faPerson, faAward, faBan, faBaseball, faCheck, faBus, faBasketball, faBirthdayCake, faBoltLightning, faBook, faBowlingBall, faBusSimple, faCalculator, faCalendarWeek, faCameraRetro, faCancel, faCheckDouble, faChess, faClipboardList, faDrum, faDrumstickBite, faEye, faFlagUsa, faFlask, faFootball, faGhost, faGolfBall, faGraduationCap, faHandHoldingMedical, faHeart, faHighlighter, faHockeyPuck, faMusic, faNewspaper, faPersonBiking, faPersonChalkboard, faPersonDigging, faPersonRunning, faPersonWalking, faRankingStar, faSchool, faSchoolCircleXmark, faSchoolFlag, faSchoolLock, faShirt, faSkiing, faSnowboarding, faSoccerBall, faSquare, faStar, faStopwatch, faTableTennisPaddleBall, faTooth, faTriangleExclamation, faTShirt, faUmbrella, faUmbrellaBeach, faUserNinja, faUtensils, faVolleyball, faWandMagicSparkles, faCandyCane, faPeopleRoof, faFire } from '@fortawesome/free-solid-svg-icons';

const EVENT_TAGS = {
    // Actions
    NO_SCHOOL: 'no school', // list of keywords
    CANCEL: 'cancel', // AI
    CLOSE: 'close', // keywords
    RESCHEDULE: 'reschedule', // AI
    UPDATE: 'update', // AI - means an event changed from before
    DEADLINE: 'deadline', // keywords
    REGISTER: 'register', // keywords
    NOTICE: 'notice', // keywords
    TENTATIVE: 'tentative', // AI
    SURVEY: 'survey',

    // Types
    SPORTS: 'sports', // list of sports
    ACADEMICS: 'academics', // list of study subjects
    HEALTH: 'health', // list of doctors, or health
    START: 'start', // 
    END: 'end', // 
    AWARDS: 'awards',

    // Human
    TEACHER: 'teacher', // list of teacher names
    PARENT: 'parent', // list of parent names
    FAMILY: 'family', // list of family

    // Activities
    ACTIVITY: 'activity', // list of activities
    // TODO: Celebrations - awards, graduations
    EXAM: 'exam', // list of exam
    HOMEWORK: 'homework', // list of homework
    FIELDTRIP: 'fieldtrip', // list of fieldtrip
    READ: 'read',
    CONCERT: 'concert', // list of concerts
    WEAR: 'wear', // wear events
    MEAL: 'meal', // meal events

    HOLIDAY: 'holiday', // list of holidays
    VACATION: 'vacation', // vacations
    CELEBRATE: 'celebrate',
}

let _firstView = false;

const EventIconLabel = ({ firstView, eventName, includeLabel, tags }) => {
    _firstView = firstView;
    let icon, label, action, type, activity, people;
    const eventNameLC = eventName.toLowerCase();

    if (tags.length > 0) {
        // actions
        switch (true) {
            case tags.includes(EVENT_TAGS.NO_SCHOOL):
                action = { tag: EVENT_TAGS.NO_SCHOOL, icon: faSchoolCircleXmark, label: 'No School' }
                break;
            case tags.includes(EVENT_TAGS.CANCEL):
                action = { tag: EVENT_TAGS.CANCEL, icon: faCalendarXmark, label: 'Cancelled' }
                break;
            case tags.includes(EVENT_TAGS.CLOSE):
                action = { tag: EVENT_TAGS.CLOSE, icon: faBan, label: 'Closure' }
                break;
            case tags.includes(EVENT_TAGS.RESCHEDULE):
                action = { tag: EVENT_TAGS.RESCHEDULE, icon: faCalendarPlus, label: 'Rescheduled' }
                break;
            case tags.includes(EVENT_TAGS.UPDATE):
                action = { tag: EVENT_TAGS.UPDATE, icon: faCalendarCheck, label: 'Updated' }
                break;
            case tags.includes(EVENT_TAGS.DEADLINE):
                action = { tag: EVENT_TAGS.DEADLINE, icon: faTriangleExclamation, label: 'Due Date' }
                break;
            case tags.includes(EVENT_TAGS.REGISTER):
                action = { tag: EVENT_TAGS.REGISTER, icon: faSquareCheck, label: 'Register' }
                break;
            case tags.includes(EVENT_TAGS.NOTICE):
                action = { tag: EVENT_TAGS.NOTICE, icon: faFlag, label: 'Notice' }
                break;
            case tags.includes(EVENT_TAGS.TENTATIVE):
                action = { tag: EVENT_TAGS.TENTATIVE, icon: faCalendarWeek, label: 'Tentative' }
                break;
            case tags.includes(EVENT_TAGS.SURVEY):
                action = { tag: EVENT_TAGS.SURVEY, icon: faClipboardList, label: 'Survey' }
                break;

            default:
                break;
        }

        // types
        switch (true) {
            case tags.includes(EVENT_TAGS.START):
                type = { tag: EVENT_TAGS.BEGIN_END, icon: faStarRegular, label: 'First Day' }
                break;

            case tags.includes(EVENT_TAGS.END):
                type = { tag: EVENT_TAGS.END, icon: faStar, label: 'Last Day' }
                break;

            case tags.includes(EVENT_TAGS.AWARDS):
                type = { tag: EVENT_TAGS.AWARDS, icon: faAward, label: 'Awards Event' }
                break;

            case tags.includes(EVENT_TAGS.ACADEMICS):
                switch (true) {
                    case eventNameLC.includes('science'):
                        type = { tag: EVENT_TAGS.ACADEMICS, icon: faFlask, label: 'Science' }
                        break;
                    case eventNameLC.includes('math'):
                        type = { tag: EVENT_TAGS.ACADEMICS, icon: faCalculator, label: 'Math' }
                        break;

                    default:
                        type = { tag: EVENT_TAGS.ACADEMICS, icon: faSchoolFlag, label: 'Academics' }
                        break;
                }
                break;

            case tags.includes(EVENT_TAGS.SPORTS):
                switch (true) {
                    case eventName.includes('FC'):
                    case eventNameLC.includes('soccer'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faSoccerBall, label: 'Soccer' }
                        break;
                    case eventNameLC.includes('basketball'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faBasketball, label: 'Basketball' }
                        break;
                    case eventNameLC.includes('baseball'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faBaseball, label: 'Baseball' }
                        break;
                    case eventNameLC.includes('volleyball'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faVolleyball, label: 'Volleyball' }
                        break;
                    case eventNameLC.includes('tennis'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faTableTennisPaddleBall, label: 'Tennis' }
                        break;
                    case eventNameLC.includes('snowboard'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faSnowboarding, label: 'Snowboarding' }
                        break;
                    case eventNameLC.includes('ski'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faSkiing, label: 'Skiing' }
                        break;
                    case eventNameLC.includes('football'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faFootball, label: 'Football' }
                        break;
                    case eventNameLC.includes('chess'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faChess, label: 'Chess Club' }
                        break;
                    case eventNameLC.includes('run'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faPersonRunning, label: 'Running Event' }
                        break;
                    case eventNameLC.includes('bike'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faPersonBiking, label: 'Bicycle Event' }
                        break;
                    case eventNameLC.includes('bowling'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faBowlingBall, label: 'Bowling' }
                        break;
                    case eventNameLC.includes('golf'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faGolfBall, label: 'Golf' }
                        break;
                    case eventNameLC.includes('hockey'):
                        type = { tag: EVENT_TAGS.SPORTS, icon: faHockeyPuck, label: 'Hockey' }
                        break;

                    default:
                        type = { tag: EVENT_TAGS.SPORTS, icon: faRankingStar, label: 'Sports' }
                        break;
                }
                break;

            case tags.includes(EVENT_TAGS.HEALTH):
                switch (true) {
                    case eventNameLC.includes('eye doctor'):
                    case eventNameLC.includes('optometrist'):
                    case eventNameLC.includes('ophthalmologist'):
                        type = { tag: EVENT_TAGS.HEALTH, icon: faEye, label: 'Health' }
                        break;
                    case eventNameLC.includes('orthodontist'):
                    case eventNameLC.includes('dentist'):
                    case eventNameLC.includes('braces'):
                        type = { tag: EVENT_TAGS.HEALTH, icon: faTooth, label: 'Health' }
                        break;

                    default:
                        type = { tag: EVENT_TAGS.HEALTH, icon: faHandHoldingMedical, label: 'Health' }
                        break;
                }
                break;

            default:
                break;
        }

        switch (true) {
            case tags.includes(EVENT_TAGS.TEACHER):
                people = { tag: EVENT_TAGS.TEACHER, icon: faPersonChalkboard, label: 'Teacher Event' }
                break;
            case tags.includes(EVENT_TAGS.PARENT):
                people = { tag: EVENT_TAGS.PARENT, icon: faPerson, label: 'Parent Event' }
                break;
            case tags.includes(EVENT_TAGS.FAMILY):
                people = { tag: EVENT_TAGS.FAMILY, icon: faPeopleRoof, label: 'Family Event' }
                break;
            default:
                break;
        }

        // activities
        switch (true) {
            case tags.includes(EVENT_TAGS.ACTIVITY):
                activity = { tag: EVENT_TAGS.ACTIVITY, icon: faCheck, label: 'Activity' }
                break;
            case tags.includes(EVENT_TAGS.EXAM):
                activity = { tag: EVENT_TAGS.EXAM, icon: faCheckDouble, label: 'Exam' }
                break;
            case tags.includes(EVENT_TAGS.HOMEWORK):
                activity = { tag: EVENT_TAGS.HOMEWORK, icon: faHighlighter, label: 'Homework' }
                break;
            case tags.includes(EVENT_TAGS.READ):
                activity = { tag: EVENT_TAGS.READ, icon: faBook, label: 'Reading' }
                break;
            case tags.includes(EVENT_TAGS.FIELDTRIP):
                switch (true) {
                    case eventNameLC.includes('walk'):
                        activity = { tag: EVENT_TAGS.FIELDTRIP, icon: faPersonWalking, label: 'Field Trip' }
                        break;
                    case eventNameLC.includes('bus'):
                    default:
                        activity = { tag: EVENT_TAGS.FIELDTRIP, icon: faBusSimple, label: 'Field Trip' }
                        break;
                }
                break;
            case tags.includes(EVENT_TAGS.CONCERT):
                activity = { tag: EVENT_TAGS.CONCERT, icon: faMusic, label: 'Music Event' }
                break;
            case tags.includes(EVENT_TAGS.WEAR):
                activity = { tag: EVENT_TAGS.WEAR, icon: faTShirt, label: 'Wear Event' }
                break;
            case tags.includes(EVENT_TAGS.MEAL):
                activity = { tag: EVENT_TAGS.MEAL, icon: faUtensils, label: 'Meal Time' }
                break;
            case tags.includes(EVENT_TAGS.HOLIDAY):
                switch (true) {
                    case eventNameLC.includes('independence'):
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faFlagUsa, label: 'Independence Day' }
                        break;
                    case eventNameLC.includes('xmas'):
                    case eventNameLC.includes('x-mas'):
                    case eventNameLC.includes('christmas'):
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faCandyCane, label: 'Christmas' }
                        break;
                    case eventNameLC.includes('halloween'):
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faGhost, label: 'Halloween' }
                        break;
                    case eventNameLC.includes('valentine'):
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faHeart, label: "Valentine's" }

                        break;
                    case eventNameLC.includes('thanksgiving'):
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faDrumstickBite, label: 'Thanksgiving' }
                        break;
                    default:
                        activity = { tag: EVENT_TAGS.HOLIDAY, icon: faCalendar, label: 'Holiday' }
                        break;
                }
                break;
            case tags.includes(EVENT_TAGS.VACATION):
                activity = { tag: EVENT_TAGS.ACTIVITY, icon: faUmbrellaBeach, label: 'Vacation' }
                break;

            case tags.includes(EVENT_TAGS.CELEBRATE):
                switch (true) {

                    case eventNameLC.includes('welcome'):
                        activity = { tag: EVENT_TAGS.CELEBRATE, icon: faWandMagicSparkles, label: 'Welcome' }
                        break;
                    case eventNameLC.includes('birthday'):
                    case eventNameLC.includes('bday'):
                        activity = { tag: EVENT_TAGS.CELEBRATE, icon: faBirthdayCake, label: 'Birthday' }
                        break;
                    case eventNameLC.includes('graduation'):
                        activity = { tag: EVENT_TAGS.CELEBRATE, icon: faGraduationCap, label: 'Graduation' }
                        break;

                    default:
                        activity = { tag: EVENT_TAGS.CELEBRATE, icon: faFire, label: 'Celebration' }
                        break;
                }
                break;

            default:
                break;
        }
    }

    if (activity) { //set activities first
        label = activity.label;
        icon = <FontAwesomeIcon className="icon" icon={activity.icon} />
    }

    if (type) { //override w/ types if present
        label = type.label;
        icon = <FontAwesomeIcon className="icon" icon={type.icon} />
    }

    if (people) { //overide /w people if present
        label = people.label;
        icon = <FontAwesomeIcon className="icon" icon={people.icon} />
    }

    if (action) { //actions are most important
        if (label) { // prefix action if label exists
            label = action.label + ': ' + label;

        } else { //add action if no label exists present
            label = action.label;
        }

        if (action.tag == EVENT_TAGS.NO_SCHOOL) { // no school overrides all
            label = action.label;
        }

        icon = <FontAwesomeIcon className="icon" icon={action.icon} />
    }

    if (!icon && !label) { // Default, and MISC.
        switch (true) {
            case eventName == 'like':
                icon = <FontAwesomeIcon className="icon like" icon={faBoltLightning} />;
                label = 'Heart';
                break;
            case eventNameLC.includes('photo'):
            case eventNameLC.includes('picture'):
                icon = <FontAwesomeIcon className="icon" icon={faCameraRetro} />;
                label = 'Photos';
                break;
            case eventNameLC.includes('construction'):
                icon = <FontAwesomeIcon className="icon" icon={faPersonDigging} />;
                label = 'Construction';
                break;
            case eventNameLC.includes('school'):
                icon = <FontAwesomeIcon className="icon" icon={faSchool} />;
                label = 'School Event';
                break;
            case eventNameLC.includes('weather'):
                icon = <FontAwesomeIcon className="icon" icon={faUmbrella} />;
                label = 'Weather Notice';
                break;
            case eventNameLC.includes('band'):
                icon = <FontAwesomeIcon className="icon" icon={faDrum} />;
                label = 'Band';
                break;
            case eventNameLC.includes('news '):
            case eventNameLC.includes(' news'):
            case eventNameLC.includes('newsletter'):
                icon = <FontAwesomeIcon className="icon" icon={faNewspaper} />;
                label = 'News';
                break;
            case eventNameLC.includes('ninja'):
                icon = <FontAwesomeIcon className="icon" icon={faUserNinja} />;
                label = 'Ninja';
                break;

            default:
                if (includeLabel) icon = <FontAwesomeIcon className="icon" icon={faCalendarCheck} />;
                label = 'Event';
        }
    }

    return (
        <>
            {icon}{includeLabel ? label : null}
        </>
    );
};

export default EventIconLabel;